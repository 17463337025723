<template>
  <div class="success">
    <h1>Booking payment was successful!</h1>
    <p>
      <!--{{ route_query.id  }}<br>
      {{ authToken_userId }}<br>-->
      {{ timedMessage }}
      <!--{{ token }}this works-->

    </p>
  </div>
</template>

<script>
import {eventBus} from '../main'
import store from '../store'

export default {

  created(){
    console.log(this.$router.history)
    eventBus.updateDBUsingQuoteId(this.$route.query.id,"check-payment")
    this.authToken_userId = store.getters.userId
    this.token = store.getters.token
  },
  mounted() {
    console.log("success.vue|mounted()|query", this.$route.query.id)
    setTimeout(() => {this.timedMessage ='reloading app.petcarry.uk in 4'},1000)
    setTimeout(() => {this.timedMessage ='reloading app.petcarry.uk in 3'},2000)
    setTimeout(() => {this.timedMessage ='reloading app.petcarry.uk in 2'},3000)
    setTimeout(() => {this.timedMessage ='reloading app.petcarry.uk in 1'},4000)
    setTimeout( () => {this.$router.replace({name: 'Home'})},5000)
  },
  data() {
    return {
    route_query: this.$route.query,
    authToken_userId: null,
    token: null,
    timedMessage: 'reloading app.petcarry.uk in 5'
    }
  },
  methods: {
    
  }
}
</script>