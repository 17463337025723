<template>
    <div>

        {{title}} 
        <!-- todo using v-show these render before existing & throw error-->
        
        {{ addressDetails.contactName }} ({{ addressDetails.natureOfContact}})<br>
        Tel:- {{ addressDetails.contactPhone }} <br>Email:- {{ addressDetails.contactEmail }}<br>
        {{ addressDetails.address }}
        {{ addressDetails.town }} , {{ addressDetails.postcode }} 
        <hr>
        <!-- {{ addressDetails }} -->
    </div>
</template>

<script>
export default {
     props: {
     addressDetails: {
       type: Object
     },
     title: {
       type: String
     }
   }
}
</script>

<style lang="scss" scoped>
ul {
    list-style-type: none;
    width: 250px;
  
}
div {
    text-align: left;
}

li {
    background-color: lightblue; 
    text-align: left;
    padding: 2px;
}
</style>