<template>
 <div>  
      <table>
      <tr>
        <td>
          <h3>{{ title }} details.</h3>
          <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="submit()">
          <div class="form-control">
            <ValidationProvider rules="required|contactname" v-slot="{ errors }">
            <label for="contact-name">Contact name  </label>
            <input id="contact-name" 
            name="contact-name" 
            type="text"
            v-model="contactName">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-control">
            <ValidationProvider rules="required|numeric|length:11" v-slot="{ errors }">
            <label for="contact-phone">Contact phone   </label>
            <input id="contact-phone" name="contact-phone" type="number" v-model="contactPhone">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
           <div class="form-control">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
            <label for="contact-email">Contact email </label>
            <input id="contact-email" name="contact-email" type="text" v-model="contactEmail">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-control">
            <!--      todo           -->
            <ValidationProvider rules="required" v-slot="{ errors }">
               <label for="nature-of-contact">{{ title }} is  </label>
          <select name="nature-of-contact" id="nature-of-contact" v-model="natureOfContact">
            <option value="customer">Me the customer</option>
            <option value="familyMember">Family member</option>
            <option value="seller">Seller</option>
            <option value="breeder">Breeder</option>
            <option value="kennel-cattery">Kennel/Cattery</option>
            <option value="rehoming">Rehoming</option>
            <option value="customs">Customs</option>
            <option value="customs">Other</option>
          </select>
          <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
          </ValidationProvider>
          </div>
          <br>
          <div class="form-control">
            <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="address">*address  </label>
            <input id="address" name="address" type="text" v-model="address">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-control">
            <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="town">*Town  </label>
            <input id="town" name="town" type="text" v-model="town">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-control">
            <ValidationProvider rules="required|postcodeMatch" v-slot="{ errors }">
            <label for="postcode">*Postcode  </label>
            <input id="postcode" name="postcode" type="text" v-model="postcode">
            <br><span :class="`is-${invalid}`">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
<!--
          <div class="form-control">
          <label for="animal">Animal is  </label>
          <select name="animal" id="animal" v-model="animalType">
            <option value="dog-small">Dog - small</option>
            <option value="dog-medium">Dog - medium</option>
            <option value="dog-large">Dog - large</option>
            <option value="cat">Cat</option>
            <option value="puppy">Puppy</option>
            <option value="kitten">Kitten</option>
            <option value="other">Other</option>
          </select>
          </div>
-->
            <br><br><button type="submit" :disabled="invalid">submit {{ title }}</button>
          </form>
            </ValidationObserver> 
        </td> 
        <td>
        </td>
      </tr>
    </table>
 </div>
</template>

<script>
//todo import home from '../views/Home'

export default {
  created(){
    this.test1()
    //console.log("addressform|created")
    //console.log(this.addressDetails)
    this.address = this.addressDetails.address
    this.contactName = this.addressDetails.contactName
    this.contactEmail = this.addressDetails.contactEmail
    this.contactPhone = this.addressDetails.contactPhone
    this.natureOfContact = this.addressDetails.natureOfContact
    this.postcode = this.addressDetails.postcode
    this.town = this.addressDetails.town
    //console.log("exit created")
  },
  mounted(){
    this.test2()
  },
   props: {
     nameValue: {
       type: String
     },
     addressDetails: {
       type: Object
     },
     title: {
       type: String
     }
   },
   emits: ['update:modelValue'],
   data() {
    return {
      //contactName: this.addressDetails.contactName,
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      natureOfContact: '',
      address: '',
      town: '',
      postcode: ''
    }
  },
  methods: {
    test2(){
      //console.log("mounted test2")
      //console.log(this.addressDetails)
      //console.log("exit test2")
    },
    test1(){
      //console.log("created test1")
      //console.log(this.addressDetails)
      //this.contactName = 'test'
    },
    updateName(nameValue){
      this.$emit('input', nameValue)
    },
    submit(addressDetails){
      addressDetails = {
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      natureOfContact: this.natureOfContact,
      address: this.address,
      town: this.town,
      postcode: this.postcode
      }
    
      this.contactName = ''
      this.contactPhone = ''
      this.contactEmail = ''
      this.natureOfContact = ''
      this.address = ''
      this.town = ''
      this.postcode = ''

      this.$emit('input', addressDetails)
      //console.log(this.animalType)
    }
  }
}

</script>

<style scoped lang="scss">
span{
  &.is-true{
    color: red;
  }

}
label{
    display: inline-block;
    float: left;
    clear: left;
    width: 180px;
    text-align: left;
}
input {
  display: inline-block;
  float: left;
}
button {
  width: 200px;
  height: 40px;
}


.form-control {
  //background-color: lightblue;

}

div {
  padding: 3px;
  background-color: lightblue;

}
body {
  padding: 30px;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin:  10px;
}

a {
  color:navy;
}
a.hover {
  color:coral;
}
</style>
