<template>
    <div>
       <hr>{{ db_key }}<hr>
      <button @click="deleteRecord(db_key)">delete record</button>
        
        <hr>
        <ul>
          <div v-for="(item,key) in record" :key="item.id">
             <li v-if="typeof item === 'object' && item !== null">
               <b>{{ key }}</b>
              <div class="flex" v-for="(each,sub_key) in item" :key="each.id">
                <button :style="buttonStyle[key + sub_key]" @click="updateRecord(db_key,key,sub_key)">U</button>
                {{ sub_key }} 
                <input v-model=localRecord[key][sub_key] type="text">
              </div>
            </li>
            <li v-else>
              <b>{{ key }} -</b> {{ item }}
            </li>
          </div>
        </ul>
        
    </div>
</template>

<script>
//import Vue from 'vue'
import {eventBus} from '../main'
export default {
  created(){
    eventBus.$on('update', (key,sub_key) => {
      console.log("EVENTBUS************",key,sub_key)
      //this.setButtonStyle('updated',key,sub_key)
      setTimeout(() => {
        this.setButtonStyle('updated',key,sub_key)
      },600)
      
      setTimeout(() =>{
        this.setButtonStyle('none',key,sub_key)
      },4000)    

    })

  },
     props: {
     record: {
       type: Object
     },
     db_key: {
       type: String
     },
     title: {
       type: String
     },
     parentIndex: {
       type: Number
     }
   },
   emits: ['deleteRecord','updateRecord'],
   data() {
     return {
       localRecord: this.record,
       buttonStyle: {}
     }
   },
   methods: {
     setButtonStyle(state,key,sub_key){
       //console.log("key & sub_key",key,sub_key)
       let superKey = key + sub_key
       //console.log("superKey",superKey)
       if (state == 'updating'){
          //this.buttonStyle[superKey] = {color: 'black',background: 'yellow'}
          this.$set(this.buttonStyle,superKey,{color: 'black',background: 'yellow'})
       }else if (state == 'updated'){
         //this.buttonStyle[superKey] = {color: 'white', background: "green"}
         this.$set(this.buttonStyle,superKey,{color: 'white', background: "green"})
       }else{
          //this.buttonStyle[superKey] = {color: "black"}
          console.log("setButtomStyle()->else route")
          //todo - make reactive?
          //this.buttonStyle[superKey] = {color: 'black', background: "grey"}
          this.$set(this.buttonStyle,superKey,{color: "black", background: 'grey'})
          console.log(this.buttonStyle)
       }
       
     },
     getItem(db_key,key,sub_key){
       console.log(db_key,key,sub_key)
      //console.log(this.localRecord.pickup.town)
      let temp = this.localRecord[key][sub_key]
      console.log(temp)
     },
     updateRecord(db_key,key,sub_key){
       console.log("record|updateRecord()")
       console.log(db_key,key,sub_key)
       let value = this.localRecord[key][sub_key]
       console.log(value)
       this.setButtonStyle('updating',key,sub_key)
       console.log(this.buttonStyle)
       this.$emit('updateRecord',db_key,key,sub_key,value,this.parentIndex)
     },
     deleteRecord(db_key){
       console.log("record|deleteRecord(db_key)")
       console.log(db_key)
       this.$emit('deleteRecord', db_key)
     }
   },
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
input {
  text-align: right;
  width: 100%;
}
ul {
    list-style-type: none;
    width: 250px;
  
}
div {
    text-align: left;
}

li {
    background-color: lightblue; 
    text-align: left;
    padding: 2px;
}
</style>