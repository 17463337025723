<template>
 <div>
      <table>
      <tr>
       
        <td>
          
          <h3>Quotation details.</h3>
          <ul v-if="trip_data.pickup"> 
            <li>Price: {{ price }} pounds.</li>
            <li>Date: {{ date }} </li>
            <li>Distance: {{ Math.floor(trip_data.route_dist) }} miles.</li>
            <li>Pickup area: {{ trip_data.pickup.substring(0,4) }}</li>
            <li>Delivery area: {{ trip_data.dropoff.substring(0,4) }}</li>
          </ul>
          <p v-else>No quote exists ...<br>Please obtain a fresh quote to continue to make a booking.</p>
          
        </td>
        </tr><tr> 
         <td>
          <button v-if="trip_data.pickup" v-on:click="acceptQuote()">Accept Quote</button>
        </td>
      </tr>
    </table>
 </div>
</template>

<script>
//todo import home from '../views/Home'

export default {
   props: ['trip_data', 'price', 'date'],
   emits: ['clicked-quote'],
   data() {
    return {
    }
  },
  methods: {
    updateQuote(){
      console.log(this.$route.params)
    },
     acceptQuote() {
      this.$emit('clicked-quote');
    }
  }
}

</script>

<style lang="scss" scoped>
td {
  width:450px;
  text-align: center;
}

button {
  width: 200px;
  height: 40px;
}
li {
  text-align: left;
  //margin-left: 45%;
}
ul {
  list-style-type: none;
  list-style-position: inside;
  width: 250px;
  background-color: lightblue; 
  margin-left: 60px;
  padding: 40px;
  align-content: center;
}
</style>