import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
//import firebaseAdmin from 'firebase-admin'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, alpha, numeric, length, email } from 'vee-validate/dist/rules'
//eventBus for admin->record comms initially
export const eventBus = new Vue({
  methods: {
    //global shared eventBus methods
    updateDBUsingQuoteId(quoteId,value){
      console.log("eventBus|updateDBUsingQuoteId()")
      console.log(quoteId,value)
      //get db ref
      let dataRef = firebase.database().ref('data')
      //get correct record key that has quoteId
      let theKey
      dataRef.orderByChild('quoteId').equalTo(quoteId).on("child_added", function(snapshot){
       
        theKey = snapshot.key
        //console.log(snapshot.val().quoteId)
        //console.log("theKey >",theKey)
        //now update status attribute
        dataRef = firebase.database().ref('data/' + theKey)
        try {
          //update item with value
          dataRef.update({'status':value})
        } catch (error) {
          console.log(error)
        }

      })
     
    }
  }
});

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  //credential: applicationDefault(),
  apiKey: "AIzaSyBjhWRwSpwQqfKQD547fhcpElcr0hLOj6o",
  //authDomain: "petcarry-ed95a.firebaseapp.com",
  authDomain: "app.petcarry.uk",
  databaseURL: "https://petcarry-ed95a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "petcarry-ed95a",
  storageBucket: "petcarry-ed95a.appspot.com",
  messagingSenderId: "616444168276",
  appId: "1:616444168276:web:8a7a87eee6b02b2a8585c1",
  measurementId: "G-5F1465BD6P"
};
firebase.initializeApp(firebaseConfig);
// Add a rule.


extend('contactname', {
  validate: value => value.length > 2,
  message: '3 or more letters'
})
extend('email',email)
extend('alpha',alpha)
extend('numeric',numeric)
extend('required',{ ...required, message: 'input required'})
extend('length',{ ...length, message: '11 digit phone number'})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//firebase.initializeApp(firebaseConfig);
