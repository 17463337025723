<template>
<div>
    <h3>Sign in</h3>
    <form @submit.prevent="submit()">
        <label for="">email</label>
        <input type="email" v-model="email"><br>
        <label for="">password</label>
        <input type="password" v-model="password"><br>
        <button type="submit">submit</button>
    </form>
    {{ message }}
</div>    
</template>
<script>
//import axios from 'axios'
export default {
    data() {
        return {
            email: 'enter your email',
            password: '',
            disabled: '',
            message: null
        }
    },
    methods: {
        async submit(){
            const data = {
                email: this.email,
                password: this.password
            }
            
            await this.$store.dispatch('signin',{email: data.email, password: data.password})
            .then(res => {
                console.log(res)
                })
            .catch(error => {
                console.log(error)
                })
        }  
    }
   
}
</script>