var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',[_c('tr',[_c('td',[_c('h3',[_vm._v(_vm._s(_vm.title)+" details.")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required|contactname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contact-name"}},[_vm._v("Contact name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactName),expression:"contactName"}],attrs:{"id":"contact-name","name":"contact-name","type":"text"},domProps:{"value":(_vm.contactName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contactName=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contact-phone"}},[_vm._v("Contact phone ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactPhone),expression:"contactPhone"}],attrs:{"id":"contact-phone","name":"contact-phone","type":"number"},domProps:{"value":(_vm.contactPhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contactPhone=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contact-email"}},[_vm._v("Contact email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactEmail),expression:"contactEmail"}],attrs:{"id":"contact-email","name":"contact-email","type":"text"},domProps:{"value":(_vm.contactEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contactEmail=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"nature-of-contact"}},[_vm._v(_vm._s(_vm.title)+" is ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.natureOfContact),expression:"natureOfContact"}],attrs:{"name":"nature-of-contact","id":"nature-of-contact"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.natureOfContact=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"customer"}},[_vm._v("Me the customer")]),_c('option',{attrs:{"value":"familyMember"}},[_vm._v("Family member")]),_c('option',{attrs:{"value":"seller"}},[_vm._v("Seller")]),_c('option',{attrs:{"value":"breeder"}},[_vm._v("Breeder")]),_c('option',{attrs:{"value":"kennel-cattery"}},[_vm._v("Kennel/Cattery")]),_c('option',{attrs:{"value":"rehoming"}},[_vm._v("Rehoming")]),_c('option',{attrs:{"value":"customs"}},[_vm._v("Customs")]),_c('option',{attrs:{"value":"customs"}},[_vm._v("Other")])]),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"address"}},[_vm._v("*address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],attrs:{"id":"address","name":"address","type":"text"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"town"}},[_vm._v("*Town ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.town),expression:"town"}],attrs:{"id":"town","name":"town","type":"text"},domProps:{"value":(_vm.town)},on:{"input":function($event){if($event.target.composing){ return; }_vm.town=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-control"},[_c('ValidationProvider',{attrs:{"rules":"required|postcodeMatch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"postcode"}},[_vm._v("*Postcode ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postcode),expression:"postcode"}],attrs:{"id":"postcode","name":"postcode","type":"text"},domProps:{"value":(_vm.postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postcode=$event.target.value}}}),_c('br'),_c('span',{class:("is-" + invalid)},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('br'),_c('button',{attrs:{"type":"submit","disabled":invalid}},[_vm._v("submit "+_vm._s(_vm.title))])])]}}])})],1),_c('td')])])])}
var staticRenderFns = []

export { render, staticRenderFns }