<template>
<div>
  <h3>app.petcarry.uk</h3>
<p>Just follow the steps below to save 5% from your bill.</p>
<ul>
    <li>
        1. Verify your email.
    </li>
    <li>
        2. Accept a browser cookie.
    </li>
    <li>
        3. Provide & confirm delivery details.
    </li>
    <li>
        4. Pay a booking fee (45.00gbp) to secure date.
    </li>
</ul>
<div class='form'>
    <button v-if="stage == null" @click="verify()">Yes, verify my email!</button>
    <form @submit.prevent="submit()">
        <label v-if="stage == 'verify'" for="">enter email</label>
        <input v-if="stage == 'verify'" v-model="email" type="text">
        <button v-if="stage == 'verify'" type="submit">submit email</button>
    </form>
    <form @submit.prevent="codeSubmit()">
        <label v-if="stage == 'messaged'" for="">{{ this.codesubmitMessage }}</label><br>
        <input v-if="stage == 'messaged'" v-model="code" type="text">
        <button v-if="stage == 'messaged'"  type="submit">submit code</button>
    </form>
    </div>
    <p v-if="errorMessage == true"> 
        {{ messageOnError }}<br>
        Apologies!<br>
        Please get another quote OR try later. 
    </p>
<!--{{ this.passedToken }}
{{ this.quote }}-->
</div>
</template>
<script>
import axios from 'axios'

export default {
    created(){
    
    },
    data() {
        return {
            errorMessage: false,
            messageOnError: '',
            quote: null,
            stage: null,
            email: null,
            codesubmitMessage: 'Please enter the 4 digit code we have emailed to you',
            code: null,
            codeNumber: null,
            passedToken: this.$route.params.id.substring(3),
            backEnd: process.env.VUE_APP_BACKEND
        }
    },
    methods: {
        callBackend(){
            //call backend with passedToken containing quoteId
            console.log(this.backEnd + '/api/quote' + '?id=' + this.passedToken + '&email=' + this.email)
            axios.get(this.backEnd + '/api/quote' + '?id=' + this.passedToken + "&email=" + this.email)
            .then(res => {
                //this.tripDataPresent = true
                const data = res.data 
                //console.log("AXIOS GOT A RESULT") 
                //this.trip_data = data.trip_data
                //this.quote = data.quote
                console.log("returned data",data.codeNumber)
                //console.log(data.quote)
                //added quoteNumber on return api json response:
                let modQuote = data.quote
                let quoteNumber = data.quoteNumber
                modQuote.id = quoteNumber
                //tag code into quote & set in user after it's pulled down from db in home
                modQuote.verifyCode = data.codeNumber 
                this.quote = modQuote
                this.$store.commit('addQuote',this.quote)
                this.codeNumber = data.codeNumber
            }
            )//async code promise
            .catch(error => {
                console.log(error)
                this.stage = 'error'
                this.errorMessage = true
                this.messageOnError = 'SERVER FETCH OF DATA FAILED'
                console.log('AXIOS GOT AN ERROR')
            }) 
        },
        async codeSubmit(){
            //emailed code submition
            //console.log("codeSubmit", this.code)
            this.stage = 'code'
            if (this.code == this.codeNumber){
                //console.log("YES CODE CORRECT")
                //now email verified use email and codeNumber to get firebase token
                //store it in localstorage i.e. client browser as user is validated
                //console.log("SIGNING UP")
                if(confirm("Email confirmed, store cookie and continue?")){
                    if (await this.$store.dispatch('signup',{email: this.email, password: this.codeNumber + this.codeNumber})){
                        console.log("")
                        console.log("verify|post dispatch|email =",this.email)
                        console.log("verify|post dispatch|codeNumber =",this.codeNumber)
                        setTimeout( () => {this.$router.push({name: 'Home'})},1000)
                    }else{
                        //case signup fails
                        //TODO: resolve case no cookie but email auth exists
                        //cannot signup when already signed up must signin?
                        console.log("FAILED TO SIGNUP")
                        alert('your email failed, have you already used this email?')
                        this.code = null
                        this.email = null
                        this.stage = null
                    }
   
               
                }else{
                    console.log("NOT ACCEPT ROUTE")
                    alert('We cannot continue unless you accept cookies.')
                    //reset page 
                    
                    this.code = null
                    this.email = null
                    this.stage = null
            
                }
                //put quote in vuex store for access from home.vue
                console.log("this.quote ->", this.quote)
                
                //delay redirect to let token setting so router knows token exists on browser
               
                

            }else{
                console.log("NO CODE INCORRECT")
                this.codesubmitMessage = "INCORRECT CODE, PLEASE TRY AGAIN!"
                this.stage = 'messaged'
            }

        },
        submit(){
            //email to verify submitted
            console.log("submitted email", this.email)
            this.callBackend()
            console.log("TOKEN BELOW")
            console.log(this.passedToken)
         
            this.stage = 'messaged'
        },
        verify(){
            //
            this.stage = 'verify'
        }
    }
}
</script>
<style lang="scss">
.form {
    text-align: left;
    padding-left: 40px;
}
ul {
    list-style-type: none;
    text-align: left;

}
    
</style>