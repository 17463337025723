<template>
 <div>   
      <table>
      <tr>
        <td>
          <!--<button v-on:click="acceptDelivery()">Step 2 :- Accept Delivery</button>-->
        </td>
        <td>
         
         <!--  <addressform v-model="pickupContactName"></addressform>-->
         <!-- {{ hasFocus('collection')}} -->      
         <div v-show="hasFocus('collection')">
          <addressform title='Collection' 
          v-model="pickupAddressDetails"
          :addressDetails="pickupAddressDetails"
          @input="setFocus('delivery')"
          ></addressform>
         </div>
         
           <div v-if="!hasFocus('collection')"> 
            <addressshow title='Collection' :addressDetails="pickupAddressDetails"></addressshow>
          </div>
          
    
         <div v-show="hasFocus('delivery')">
           <addressform 
           title='Delivery' 
           v-model="dropoffAddressDetails"
           :addressDetails="dropoffAddressDetails"
           @input="setFocus('customer')"
           ></addressform>
          </div>
            <div v-if="!hasFocus('delivery') && !hasFocus('collection')"> 
            <addressshow title='Delivery' :addressDetails="dropoffAddressDetails"></addressshow>
          </div>
          
                <!-- GET CUSTOMER ADDRESS DETAILS ROUTE!-->
                <!--|{{hasFocus('customer')}}|-->
                <div v-show="hasFocus('customer')"> 
                  <addressform 
                  title='Customer' 
                  v-model="customerAddressDetails"
                  :addressDetails="customerAddressDetails"
                  @input="setFocus('done')"
                  ></addressform>
               </div>
                <div v-if="needCustomerForm() && !hasFocus('customer') && !hasFocus('collection') && !hasFocus('delivery')"> 
                  <addressshow title='Customer' :addressDetails="customerAddressDetails"></addressshow>
                </div>
                <!-- <div v-else>-->
                  <!-- CUSTOMER DETAILS & DELIVERY DETAILS DONE ROUTE! -->
        <div v-show="!hasFocus('collection') && !hasFocus('delivery') && !hasFocus('customer')">
                  <button v-on:click="acceptDelivery()">Accept Delivery details</button>
          </div>
                   
                
                <!--</div>-->
                
          
        
        </td> 
        <td>
        
         <!-- {{ pickupAddressDetails }}-->
        </td>
      </tr>
    </table>
 </div>
</template>

<script>
//todo import home from '../views/Home'
import Form from './AddressForm'
import Show from './AddressShow'
//import axios from 'axios'
import firebase from 'firebase'

export default {
  created(){
    //now below has data
    //console.log("delivery|created()")
    //console.log(this.deliveryAddressInfo)
    this.pickupAddressDetails = this.deliveryAddressInfo.pickup
    this.dropoffAddressDetails = this.deliveryAddressInfo.dropoff
    this.customerAddressDetails = this.deliveryAddressInfo.customer
    if (this.db_key !== 'none'){
      //console.log("Delivery|created()|fetchLoadData()")
      this.fetchLoadData(this.db_key)
    }
  },
  mounted() {
    //console.log("delivery|mounted()")
    //console.log(this.deliveryAddressInfo)

  },
   props: {
     deliveryAddressInfo: {
       type: Object
     },
     price: {
       type: Number
     },
     date: {
       type: String
     },
     quoteId: {
       type: String
     },
     db_key: {

     }
   },
   emits: ['update:modelValue'],
   data() {
    return {
      pickupAddressDetails: {
        contactName: '',
        natureOfContact: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        postcode: '',
        town: ''
      },
      dropoffAddressDetails: {
        contactName: '',
        natureOfContact: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        postcode: '',
        town: ''
      },
      customerAddressDetails: {
        contactName: '',
        natureOfContact: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        postcode: '',
        town: ''
      },
      updated: false,
      focus: 'collection'
    }
  },
  computed: {
  
  },
  components: {
    addressform: Form,
    addressshow: Show
  },
  methods: {
    pushToDB(deliveryAddressInfo){
      return firebase.database().ref('data').push(deliveryAddressInfo)
    },
    async createDBRecord(deliveryAddressInfo){
      const res = await this.pushToDB(deliveryAddressInfo)
      console.log("delivery|createDBRecord() key->", res.key)
      let db_key = res.key
      return db_key
    },
    updateDBRecord(db_key,deliveryAddressInfo){
      //get db ref
      let dataRef = firebase.database().ref("data/" + db_key)
      //update item with value
      dataRef.update(deliveryAddressInfo)
      //make sure key is assigned for passing back to parent
      return db_key
      
    },
    needCustomerForm(){
      //console.log("needCustomerForm()")
      //called from start when no natureOfCotact
      if (this.pickupAddressDetails.natureOfContact == undefined
      || this.dropoffAddressDetails.natureOfContact == undefined){
        //console.log("returning false from undefined route")
        return false
      }else{
        //console.log("needCustomerForm else route")
        return this.pickupAddressDetails.natureOfContact.includes('customer')
        || this.dropoffAddressDetails.natureOfContact.includes('customer') 
        ? false : (this.customerAddressDetails.natureOfContact ? false : true)
      }
    },
    setFocus(arg){
      this.focus = arg
    },
    hasFocus(stage){
      //console.log('hasFocus', stage)
      if (stage == 'customer'){
        var ans = this.needCustomerForm()
        //console.log('hasFocus returning', ans)
        return ans
      }else{
        return this.focus == stage ? true : false
      }
      
    },
    Key(){
      return(this.db_key !== 'none' && this.db_key !== null && this.db_key !== undefined)
    },
    fetchLoadData(key){
      //console.log("in fetchLoadData()")
      //console.log(key)
      //fetch record from firebase realtime db
      this.getRecord(key)
      //console.log("The record is..."),
      //console.log(this.record)
      
     
      //load this into the data
    },
    getRecord(key){
      //console.log("in getRecord()",key)
      firebase.database().ref('data').once('value')
      .then((data) => {
          //console.log(data)
          var record = {}
          const obj = data.val()
          record.pickup = obj[key].pickup ? obj[key].pickup : {}
          record.dropoff = obj[key].dropoff ? obj[key].dropoff : {}
          record.customer = obj[key].customer ? obj[key].customer : {}        
          this.pickupAddressDetails = record.pickup
          this.dropoffAddressDetails = record.dropoff
          this.customerAddressDetails = record.customer
          //console.log(record.pickup)
          //console.log(record.dropoff)
          //console.log(record.customer)
      })
      .catch((error) => {
        console.log(error)
      })
    
    },
    isCustomer(addressObject){
      //note == for nul or undefined NOT ===
      //console.log("isCustomer()")
      //console.log(addressObject)
      if (addressObject.natureOfContact == '' || addressObject === {} || addressObject.natureOfContact === undefined){
        return false
      }else{ 
        //console.log("isCustomer()|else")
        //console.log(addressObject.natureOfContact)
        if (addressObject.natureOfContact.includes('customer')){
        //console.log(addressObject.natureOfContact)
        //alert('true')
        return true
        }else{
        //console.log(addressObject.natureOfContact)
        //alert('false')
        return false
        }
      }
    },
    submit(){
    
      //console.log(this)
    },
     async acceptDelivery(deliveryAddressInfo) {
       
       deliveryAddressInfo = {
         status: 'accept-delivery',
         date: this.date,
         price: this.price,
         quoteId: this.quoteId,
         customer: this.customerAddressDetails,
         pickup: this.pickupAddressDetails,
         dropoff: this.dropoffAddressDetails
       }
       
       //console.log("ADDRESSDETAILS BELOW")
       //console.log(deliveryAddressInfo)
       console.log(this.Key() ? "HAS KEY - CAN UPDATE" : "NO KEY - NEW RECORD")
       var return_key
       if (!this.Key()){
         return_key = await this.createDBRecord(deliveryAddressInfo)
       }else{
         return_key = await this.updateDBRecord(this.db_key,deliveryAddressInfo)
       }
       //make sure the new key or existing key is returned in emit event
       
       
       //====================================================================
       /*firebase.database().ref('data').push(deliveryAddressInfo)
       .then((data) => {
           //conole.log(data)
           //const obj = data.val()
           deliveryAddressInfo.db_key = data.key
         }
       )
       .catch((error) => {
           console.log(error)
         }
       )*/
      //=====================================================================
       this.updated = true
      this.$emit('input', deliveryAddressInfo)
      //send back key set by either createDBRecord or updateDBRecord
      console.log("clicked-delivery emit payload", return_key)
      this.$emit('clicked-delivery',return_key)
    
    }
  }
}

</script>

<style scoped lang="scss">
td {
  //width:450px;
}
button{
  width: 200px;
  height: 40px;
}

label{
    display: inline-block;
    float: left;
    clear: left;
    width: 250px;
    text-align: left;
}
input {
  display: inline-block;
  float: left;
}



.form-control {
  background-color: lightblue;

}

div {
  padding: 3px;
  
}
body {
  padding: 30px;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color:navy;
}
a.hover {
  color:coral;
}
</style>
