<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="pk"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
    <button @click="submit">Pay booking fee here</button>
  Based upon quote - {{ quoteId }}.
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  props: ['quoteId'],
  emits: ['clicked-to-book'],
  components: {
    StripeCheckout,
  },
  data () {
    
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      pk: process.env.VUE_APP_PUBLIC_KEY,
      loading: false,
      lineItems: [
        {
          //price: 'price_1J60DoBrR0jZWjxF6eEjRvZx',
          //test or prod price id from env or env.production
          price: process.env.VUE_APP_PRICE_ID,
           
          // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: process.env.VUE_APP_BASE_URL + '/successful?id=' + this.quoteId,
      cancelURL: process.env.VUE_APP_BASE_URL + '/cancel?id=' + this.quoteId,
    };
  },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$emit('clicked-to-book')
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>
button {
   width: 100px;
  height: 50px;
}
</style>