<template>
<div>

<h3>
    <table><tr>
        <td>Booked Delivery Status - </td>
        <td></td>
        <td><div v-bind:style="{'color': style('confirmed')}" >Confirmed</div></td>
        |<td><div v-bind:style="{'color': style('payment')}" >Full payment</div></td>
        |<td><div v-bind:style="{'color': style('completed')}">Completed</div></td>
    </tr></table>
</h3>
<hr>
<table>
    <tr>
        <td>Customer:  {{ deliveryAddressInfo.customer.contactName }}</td>
    </tr>
    <tr><td>Delivery from {{ deliveryAddressInfo.pickup.town }} to {{ deliveryAddressInfo.dropoff.town }}</td></tr>
    <tr><td>On the date: {{ date }} </td></tr>
    <tr><td>For the sum of {{ price }} pounds in total.</td></tr>

</table>
<hr>
<button @click="changeDate">change date</button>
<button @click="cancelBooking">Cancel Booking</button>
<br>
<!--
    Quote id:- {{ quoteId }} <br>
    Date :- {{ date }} <br>
    Price: - {{ price }} <br>-->
    <!--Trip data: - {{ trip_data }} <br>-->
    <!--Delivery information:- <br>
    {{ Object.keys(deliveryAddressInfo) }} -->
</div>
</template>
<script>
export default {
    props: {
        quoteId:{
            type: String
        },
        date:{
            type: String
        },
        price:{
            type: Number
        },
        trip_data:{
            type: Object
        },
        deliveryAddressInfo:{
            type: Object
        }
    },
    emits: ['clicked-change-date', 'clicked-cancel'],
    data() {
        return {
        
        }
    },  
    methods: {
        cancelBooking(){
            //customer wants to cancel the booking
            this.$emit('clicked-cancel')
        },
        changeDate(){
            //customer want to change the date fro delivery
            this.$emit('clicked-change-date')
        },
        style(comp){
            console.log(comp)
            return 'red'
        }
    }  
}
</script>
<style lang="scss" scoped>
td {
    text-align: left;
}

</style>