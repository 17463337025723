<template>
  <div class="home">
    <hr>
  <h1>Petcarry.uk - booking app.</h1>
  <hr>
  <div v-if="ready">
  <div v-if="!done"> <!--if done don't display-->
  <hr>
  <table>
    <tr>
      <td>
        <div v-bind:style="{'color': style('quote')}">1. Quote</div>
      </td>
    
      <td>
         <div v-bind:style="{'color': style('delivery')}">2. Delivery</div>
      </td>
      <td></td>
      <td>
        <div v-bind:style="{'color': style('customer')}">3. Customer</div>
      </td>
      <td></td>
      <td>
        <div v-bind:style="{'color': style('payment')}">4. Payment</div>
      </td>
    </tr>
  </table>
  <hr>
 
  <div v-if="quoteDetails && !acceptedQuoteDetails">
    {{ setFocus('quote')   /*  this.focus = 'quote'*/}}
    <quotecomp :trip_data="trip_data" :price="price" 
    :date="date" @clicked-quote="acceptQuoteDetails()">
    </quotecomp>
  
</div>
  <template v-else-if="quoteDetails && acceptedQuoteDetails">   
    <div class="completed">    
      1. Quote accept complete - 
      (Delivery on {{ date }} 
      for {{ price }} pounds 
      from {{ trip_data.pickup }}
      to {{ trip_data.dropoff }}
    </div>   
    <hr>
  </template>
<div v-if="this.$route.query.quote && slowTripData()">
  <!-- QUOTE PARAM PROVIDED BUT NO DETAILS COULD BE FETCHED -->
  QUOTE DETAILS ARE UNAVAILABLE OR OUT OF DATE!
  </div>
     
<div v-if="!this.$route.query.quote && !tripDataPresent">
GETTING QUOTE!
</div>
 <template v-if="acceptedQuoteDetails && !deliveryDetails">
    {{ setFocus('delivery') }}
    <deliverycomp v-model="deliveryAddressInfo" 
    @clicked-delivery="acceptDeliveryDetails"
    :price="price"
    :date="date"
    :quoteId="quoteId"
    :db_key="deliveryAddressInfo.db_key"
    :deliveryAddressInfo="deliveryAddressInfo"
    ></deliverycomp>
    
    <hr>
  </template>
  <template v-else-if="acceptedQuoteDetails">
    <div class="completed">
     
      2. Delivery details complete -
    
      From {{ deliveryAddressInfo.pickup.town }} 
      to {{ deliveryAddressInfo.dropoff.town }} 
       <button v-on:click="editDeliveryDetails(deliveryAddressInfo.db_key)">edit</button>
    </div>
  
    <hr>
  </template>

<template v-if="deliveryDetails && !customerDetails">
   {{ setFocus('customer') }}
    <customercomp :deliveryAddressInfo="deliveryAddressInfo" @clicked-customer="acceptCustomerDetails()"></customercomp>
    <hr>
  </template>
  <template v-else-if="customerDetails">
    <div class="completed">
      3. Cutomer details complete for  
      {{ deliveryAddressInfo.customer.contactName }} in {{ deliveryAddressInfo.customer.town}}
    </div>
    <hr>
  </template>

  <template v-if="customerDetails && !paymentDetails">
     {{ setFocus('payment') }}
    <paymentcomp 
    :quoteId="quoteId"
    :date="date" 
    :price="price" 
    :trip_data="trip_data" 
    :deliveryAddressInfo="deliveryAddressInfo"
    @clicked-payment="acceptPaymentDetails()"
    @clicked-to-book="clickedToBook"></paymentcomp>
    <hr>
  </template>
  <template v-else-if="paymentDetails">
    <div class="completed">
      4. Payment details complete.
    </div>
    <hr>
    
  </template>
  </div> <!-- v-if !done closing div -->
  <div v-if="done">
    <!-- TODO: Display Customers Booking info -->
    <bookedstatuscomp
      :quoteId="quoteId"
      :date="date" 
      :price="price" 
      :trip_data="trip_data" 
      :deliveryAddressInfo="deliveryAddressInfo"
      @clicked-cancel="cancel"
      @clicked-change-date="changeDate"></bookedstatuscomp>
  </div>
  </div>
  </div>
</template>

<script>
//import axios from 'axios'
import BookedStatus from '../components/BookedDeliveryStatus.vue'
import Quote from '../components/Quote.vue'
import Delivery from '../components/Delivery.vue'
import Customer from '../components/Customer.vue'
import Payment from '../components/Payment.vue'
import {extend} from 'vee-validate'
import firebase from 'firebase'
import store from '../store'
import axios from 'axios'

export default {  
   async created(){
     console.log("home|created()",this.$route.params)
     let p = this.$route.params
     if (p.newQuote){
       //a new quote token was passed
       //retrieve quote from backend
        let quote = await this.callBackend(p.newQuote)
        console.log(quote)
        if (quote){
          //push to db
          console.log("PUSHING NEW QUOTE TO DB")
          if (store.getters.userId){
            this.pushQuoteToDB(store.getters.userId,quote)
          }else{
            console.log("PUSH QUOTE FAIL, NO USERID")
          }
        }else{
          //do what? server did not return valid quote
          console.log("NEW QUOTE COULD NOT BE RETRIEVED FROM BACKEND")
        }
       //put it in realtime database using userId from store
       //following code will pull it down and set quote correctly
       
     }else{
       console.log("no new quote")
     }
     //console.log(p)
     //adds OR loads user from db
     this.addUserToDB()
    
  },
  mounted(){
   
  },
  //props: ['trip_data'],
  emits: ['clicked-quote'],
  data() {
    return {
      //boolean to signify display ready i.e. set ready to display
      //after fetches etc..
      ready: false,
      userKey: null,
      deliveryDataKey: null,
      backEnd: process.env.VUE_APP_BACKEND,
      focus: 'none',    //used for color logic on progress bar
      done: false,      //done is a flag set true when getDeliveryData returns data with status 'check-payment'
      quoteColor: 'black',
      deliveryColor: 'black',
      customerColor: 'black',
      paymentColor: 'black',
      price: 0,
      date: "",
      //quote: {},
      quoteId: '',
      trip_data: {},
      //below boolean are details present
      deliveryDetails: false,   //used for display logic
      //below 2or3 address objects{pickup,delivery,customer}
      deliveryAddressInfo: {
        pickup: {},
        dropoff: {},
        customer: {},
        db_key: 'none'
      },
      customerDetails: false,
      paymentDetails: false,
      bookingFee: false,
      tripDataPresent: false,
      quoteDetails: false, //they exist
      acceptedQuoteDetails: false //button click to accept
    }
  },
  computed: {
    
  },
 
  name: 'Home',
  components: {
    bookedstatuscomp: BookedStatus,
    quotecomp: Quote,
    deliverycomp: Delivery,
    customercomp: Customer,
    paymentcomp: Payment
  },
  methods: {
    async updateUserInDB(userId,element,value){
      //made for updating user with verifyCode initially
      //get user key
      let ref = firebase.database().ref('users')
      let snap = await ref.orderByChild('userId').equalTo(userId).once('value')
      let key = Object.keys(snap.val())[0]
      let userRef = firebase.database().ref('users/' + key)
      //update user //TODO: add fault code for below
      await userRef.update({[element]:value}) 
    },
    cancel(){
      //TODO: cancel the booking
      console.log("cancel handler")
    },
    changeDate(){
      //handler for bookedDelivery component's clicked-change-date emit event
      //TODO: how to change date of booked delivery
      console.log("change date handler") 
    },
    async pushQuoteToDB(userId,quote){
      console.log("home|created()|pushQuoteToDB()")
      let ref = firebase.database().ref('users')
      //get users key that has userId
      let snap = await ref.orderByChild('userId').equalTo(userId).once('value')
      console.log(snap)
      //get 1st key from snapshot results
      let key = Object.keys(snap.val())[0]
      console.log(key)
      //update the quote element only for the record using key
      await ref.child(key).update({'quote':quote})
      //TODO: ubove works but no fault handling, handle errorss
      //also with new quote deliverDetails need reset
      this.deliveryDetails = false
      this.customerDetails = false
      this.deliveryColor = 'black'
      this.customerColor = 'black'
      //reset deliveryDataKey?
      
      //also setORreset deliveryDataKey of user
      if (store.getters.userId){
        const userRef = firebase.database().ref('users')
        let snap = await userRef.orderByChild('userId').equalTo(store.getters.userId).once('value')
        let userKey = Object.keys(snap.val())[0]
        const theUserRef = firebase.database().ref('users/' + userKey)
        //first get old key
        let oldSnap  = await theUserRef.get('deliveryDataKey')
        let oldDataKey = oldSnap.val().deliveryDataKey
        let dataRef = await firebase.database().ref('data/' + oldDataKey)
        console.log(dataRef)
        //dataRef is the old delivery data if status is not check-payment remove
        //else add a booked history list to user and leave?
        /*if (dataRef.status == 'check-payment'){ //user paid so keep record
        //TODO: make below better i.e. a list push another key on or something???
          theUserRef.update({'bookedHistory': oldDataKey})
        }else{ //old quote was not payed for so remove
          dataRef.remove() //removes old delivery data from db
        }*/
        
        theUserRef.update({'deliveryDataKey': null}) //updates the user

        console.log("end")
      }
    },
    async callBackend(token){
      //call backend with new token arg containing quoteId
      //return quote BUT also get quoteNumber & put in quote
      //axios.get(this.backEnd + '/api/quote' + '?id=' + this.passedToken + '&email=' + this.email)
      let result = await axios.get(process.env.VUE_APP_BACKEND + '/api/quote' + '?id=' + token + "&email=" + 'null@petcayy.uk')
      /*.then(res => {
          const data = res.data 
          //console.log(data.quote)
          result =  data.quote
          //this.$store.commit('addQuote',this.quote)
          //this.codeNumber = data.codeNumber
      }
      )//async code promise
      .catch(error => {
        result = 'error'
          console.log(error)
          console.log('AXIOS GOT AN ERROR')
      })*/ 
      console.log(result)
      if (result.data.quote){
        let quoteId = result.data.quoteNumber
        let quote = result.data.quote
        quote.id = quoteId
        console.log(quote,quoteId)
        return result.data.quote
      }else{
        return false
      }
    },
    quoteAvailable(){
      //check if quote is in store return boolean
      return store.getters.quote
    },
    setCustomerDetails(){
      //callers acceptDeliveryDetails & fetch methods
        //set customer as pickup|dropoof|leave as already customer details
      if (this.deliveryAddressInfo.pickup.natureOfContact.includes('customer')){
        this.deliveryAddressInfo.customer = this.deliveryAddressInfo.pickup
      }else if(this.deliveryAddressInfo.dropoff.natureOfContact.includes('customer')){
        this.deliveryAddressInfo.customer = this.deliveryAddressInfo.dropoff
      }
    },
    async getUserFromDB(userId){
      //returns user as js object
      //console.log(userId)
    
      //TODO: fix flawed user assignment as there maybe 2 users with same userId
      //below takes first result even if snapshot has many keys 
      //TODO: handle snap being null i.e. userId not in db
      try {
          var ref = firebase.database().ref('users')
          var snap = await ref.orderByChild('userId').equalTo(userId).once('value')
          var user = snap.val()[Object.keys(snap.val())[0]]
      } catch (error) {
        console.log(error)
        user = null;
      }
      
      //console.log(user)
      return user
    },
    async getDeliveryDataFromDBUsingKey(key){
      //return delivery data as js object
      let ref = firebase.database().ref('data/' + key)
      //let snap = await ref.child().equalTo('key').equalTo(key).once('value')
      let snap = await ref.get()
      let data = snap.val()
      //console.log(data)
      //console.log(snap.val())
      return data
    },
    async getDeliveryData(userId){
      //console.log("getDeliveryData()")
      //console.log(userId)
      let user =  await this.getUserFromDB(userId)
      //console.log(user)
      
      let deliveryData = await this.getDeliveryDataFromDBUsingKey(user.deliveryDataKey)
      //console.log(deliveryData)

        this.deliveryAddressInfo.pickup = deliveryData.pickup
        this.deliveryAddressInfo.dropoff = deliveryData.dropoff
        this.price = deliveryData.price
        this.quoteId = deliveryData.quoteId
        //local component status is not used?? so removing to avoid confusion
        //with db/data deliveryobject.status + have a bool done set true when status is check-payment
        //this.status = deliveryData.status

      switch(deliveryData.status){
        case 'accept-quote':
          this.acceptedQuoteDetails = true
          this.quoteDetails = true
          this.quoteColor = 'green'
          break;
        case 'accept-delivery':
          this.acceptedQuoteDetails = true
          this.quoteDetails = true
          this.quoteColor = 'green'
          this.deliveryDetails = true
          this.deliveryColor = 'green'
          break;
        case 'accept-customer':
          this.acceptedQuoteDetails = true
          this.quoteDetails = true
          this.quoteColor = 'green'
          this.deliveryDetails = true
          this.deliveryColor = 'green'
          this.customerDetails = true
          this.customerColor = 'green'
          break;
          case 'accept-paymentDetails':
            this.acceptedQuoteDetails = true
            this.quoteDetails = true
            this.quoteColor = 'green'
            this.deliveryDetails = true
            this.deliveryColor = 'green'
            this.customerDetails = true
            this.customerColor = 'green'
            this.paymentDetails = true
            this.paymentColor = 'green'
            break;
          case 'clicked-to-book':
            this.acceptedQuoteDetails = true
            this.quoteDetails = true
            this.quoteColor = 'green'
            this.deliveryDetails = true
            this.deliveryColor = 'green'
            this.customerDetails = true
            this.customerColor = 'green'
            this.paymentDetails = false
            this.paymentColor = 'red'
          break;
          case 'check-payment':
            this.acceptedQuoteDetails = true
            this.quoteDetails = true
            this.quoteColor = 'green'
            this.deliveryDetails = true
            this.deliveryColor = 'green'
            this.customerDetails = true
            this.customerColor = 'green'
            this.paymentDetails = true
            this.paymentColor = 'green'
            this.done = true
            break;
      }
      //set ready for dispalying stuff on screen
      this.ready = true
      this.setCustomerDetails()
      //console.log(deliveryData)  
      //console.log("exiting >> getDeliveryData()")
    },
    async fetchQuote(userId){
      //fetched quote from db
      console.log("home|fetchQuote()")
      var ref = firebase.database().ref();
      await ref.child('users').orderByChild('userId').equalTo(userId).once('child_added', snap => {
        this.quote = snap.val().quote
        //if quote has verifyCode add it to user
        let verifyCode = this.quote.verifyCode
        if (verifyCode){
          //TODO: update user with verifyCode
          this.updateUserInDB(userId,'verifyCode',verifyCode)
        }
        //console.log("deliveryDataKey >", snap.val().deliveryDataKey)
        //TODO: clean up below 2 keys are same data:deliveryKey
        //should be in one place
        this.deliveryDataKey = snap.val().deliveryDataKey
        this.deliveryAddressInfo.db_key = snap.val().deliveryDataKey
        this.userKey = snap.key
        //console.log(snap.val().quote)
        store.commit('addQuote',snap.val().quote)
        this.quoteDetails = true
        this.quoteColor = 'green'
        this.setQuote()
      })
    },
    async isUserInDB(userId){
      //returns boolean based upon if userId is property of a user in db
      //help stop double entries upon edit of delivery details
      let user = await this.getUserFromDB(userId)
      //console.log(user)
      if (user == null){
        console.log('isUserInDB()->no user')
        return false
      }else{
        console.log('isUserInDB()->yes user')
        return true
      }
    },
    async addUserToDB(){
      //if userId in store use it to
      //either fetch or make db record
      let userId = store.getters.userId
      this.quote = store.getters.quote
      if (userId){
        //console.log("home|addUser()>userId in store")
        //if store.userId NOT in db add user to db
        let res = await this.isUserInDB(userId)
        console.log("addUserToDB()cisUserInDB->",res)
        if (! await this.isUserInDB(userId)){
          console.log("MAKING NEW USER IN DB")
          //create user //deliveryKeys is not made???
          //is there a quote to store?

          let user = {
            userId: store.getters.userId,
            token: store.getters.token,
            quote: store.getters.quote,
            deliveryKeys: []
          }
          console.log("pushing data ..",user)
        
          //below means no push to db without quote
          if (user.quote !== null){
            firebase.database().ref('users').push(user)
            .then((data) => {
              this.userKey = data.key
              //todo - resolve userKey
              //setTimeout(() => {this.getQuote()},50)
          
            })
            .catch((error) => {
              console.log(error)
            })
            await this.fetchQuote(userId)
            if (this.deliveryDataKey){
              await this.getDeliveryData(userId)
            }else{
              //case new user but no deliveryDataKey
              //ready is not set so set it!! below
              this.ready = true
            }
         
          }
          //console.log("exiting 123")
        }else{
          console.log("GET DATA FROM DB ROUTE")
          //user exists in db get the quote
          await this.fetchQuote(userId)
        
          //console.log("deciding if deliveryDataKey", this.deliveryDataKey)
          if (this.deliveryDataKey){
            await this.getDeliveryData(userId)
          }
          this.ready = true
        }

      }else{
        console.log("NO USER IN STORE- ERROR",store.getters.userId)
      }
    },
    clickedToBook(){
      this.updateDB('status','clicked-to-book')
    },
    updateDB(element,value){
      //get db ref
      //check for db_key
      let dataRef;
      let key = null;
      console.log("updateDB() this.deliveryAddressInfo.db_key",this.deliveryAddressInfo.db_key)
      console.log("updateDB() this.deliveryDataKey", this.deliveryDataKey)
      if (this.deliveryAddressInfo.db_key !== 'none' && this.deliveryAddressInfo.db_key !== undefined){
        key = this.deliveryAddressInfo.db_key;
      }
      if (this.deliveryDataKey !== null && this.deliveryDataKey !== undefined){
        key = this.deliveryDataKey;
      }
      
      if (key == null ){
        console.log("updateDB() - ERROR XXX HAVE NO KEY")
        //upon quote accept before address details accept
        //user has no deliveryDataKey or record of delivery details
      }else{
        dataRef = firebase.database().ref("data/" + key)
      
        //console.log(this.db_key,element,value,dataRef)
        try {
          //update item with value
          dataRef.update({[element]:value})
        } catch (error) {
          console.log(error)
        }
      }

    },
    setupPostValidation(){
      //vee validation rule
      //console.log("setupPostValidation()")
      //console.log(this.trip_data.pickup.split(' ')[0])
      let test = this.trip_data.pickup.substring(0,2).toUpperCase()
      let test2 = this.trip_data.dropoff.substring(0,2).toUpperCase()
      extend('postcodeMatch', {
      //todo compare value with trip_data.pickup's postcode
      //validate: value => value.length > 2,
      validate: value => value.substring(0,2).toUpperCase().includes(this.trip_data.pickup.substring(0,2))||
       value.substring(0,2).toUpperCase().includes(this.trip_data.dropoff.substring(0,2)),
      message: test + " OR "  + test2
    })
    },
    getPostcode(){
      //console.log("home|getPostcode",this.trip_data)
      //return "KY2"
      return 
    },
    slowTripData(){
      //special time delay method for no quote message
      //delays 500ms for backend fetch of quote before displaying
      setTimeout(this.checkTripData(),500)
    },
    checkTripData(){
      if(this.tripDataPresent){
        true
      }else{
        false
      }
    },
  
   
    editDeliveryDetails(db_key){
    
      console.log("db_key")
      console.log(db_key)
      this.deliveryDetails = false
      this.customerDetails = false
    },
    setFocus(focusArg){
      this.focus = focusArg
    },
    setQuote() {
            //setQuote in local component from? db
            //assuming fetch quote happened and this.quote exists 
            //local,store,db? confusing the matter?
            //old>let quote = this.$store.getters.quote
            //console.log(quote)
            this.quoteId = this.quote.id
            this.tripDataPresent = true 
            this.trip_data = this.quote.trip_data
            this.price = this.quote.price
            this.date = this.quote.date[0].split("-").reverse().join("-")
            this.setupPostValidation()
                
      //
    },

    async acceptQuoteDetails() {
      //console.log(this)    
      this.acceptedQuoteDetails = true
      this.quoteDetails = true
      this.quoteColor = 'green'
      //no db/data record exists yet make one and set key as db_key
      const ref = firebase.database().ref('data')
      let newrecord = await ref.push({status: 'accept-quote'})
      this.db_key = newrecord.key
      this.deliveryAddressInfo.db_key = newrecord.key
      this.deliveryDataKey = newrecord.key
   
      //console.log(this)
    },
    acceptDeliveryDetails(db_key){
      this.updateDB('status','accept-delivery')
      this.deliveryDetails = true
      this.deliveryColor = 'green'
      this.deliveryAddressInfo.db_key = db_key
      //update users db account with the data db_key
      //userKey is null todo get userKey
      var ref = firebase.database().ref('users/' + this.userKey)
      //if db_key exists update into db.users
      if (db_key) {
        ref.update({'deliveryDataKey':db_key})
      }
      this.setCustomerDetails()
    
      //console.log('accept delivery details into deliveryAddressInfo!')
      //console.log(this.deliveryAddressInfo)
      
    },
    acceptCustomerDetails(){
      this.updateDB('status','accept-customer')
      this.customerDetails = true
      this.customerColor = 'green'
    },
      //console.log(this.deliveryDetails)
    
    acceptPaymentDetails(){
      this.updateDB('status','accept-paymentDetails')
      this.paymentDetails = true
      this.paymentColor = 'green'
      //console.log(this.deliveryDetails)
    },
    makeOrange(){
      this.qouteColor = 'orange'
    },
    style(comp) {
      //var buildstr = comp + 'Details'
      //console.log(comp)
      var componentDetails
      if (comp == 'quote'){
        componentDetails = this.quoteDetails
      }else if (comp == 'delivery'){
        componentDetails = this.deliveryDetails
      }else if (comp == 'customer'){
        componentDetails = this.customerDetails
      }else if (comp == 'payment'){
        componentDetails = this.paymentDetails
      }

      //console.log("componentDetails " + componentDetails)
      if (this.$route.query.quote && !componentDetails || this.tripDataPresent && !componentDetails){
        //console.log("quoteDetails is " + this.quoteDetails)
        if (this.focus == comp) {
          return 'orange'
        } else {
          return 'black'
        }
        } else if (componentDetails){
          //console.log("quoteDetails is " + this.quoteDetails)
          return 'green'
        } else {//no details or data
          if (comp == 'quote'){
            return 'red'
          } else {
            return 'black'
          }
        }
      
    }
  }
}
</script>
<style scoped lang="scss">
.completed {
  text-align: left;
}
td {
  width: 0px;
  padding: 0px;
}
body {
  padding: 30px;
}
#app {
  background-color:whitesmoke;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color:navy;
}
a.hover {
  color:coral;
}
</style>
