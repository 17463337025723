<template>
 <div>
      <table>
      <tr>
        <td>
          <div v-if="false">
            <button v-on:click="acceptPayment()">Step 4 :- Accept Payment</button>
          </div>
        </td>
        <td>
          <h2>Booking payment.</h2>
          <div>
            An online payment of 45 pounds is required to book.<br>
            This delivery is for {{ deliveryAddressInfo.customer.contactName }}<br>
            going from {{ deliveryAddressInfo.pickup.town }} to {{ deliveryAddressInfo.dropoff.town }}<br>
            Delivery date is   {{ date }}.<br>
            Total price of {{ price }} pounds sterling minus 5% discount.<br>
            <hr>
          </div>
          <div>
            <pay :quoteId="quoteId" @clicked-to-book="clickedToBook"></pay>
          </div>
                  
        </td> 
      </tr>
    </table>
 </div>
</template>

<script>
//todo import home from '../views/Home'
import Pay from './Stripe'
//import Show from './AddressShow'
export default {
   props: ['quoteId','date', 'price', 'trip_data', 'deliveryAddressInfo'],
   emits:  ['clicked-to-book'],
   data() {
    return {
  
    }
  },
  components: {
    pay: Pay,
    //addressshow: Show
  },
  methods: {
    clickedToBook(){
      this.$emit('clicked-to-book')
    },
     acceptPayment() {
      this.$emit('clicked-payment');
    }
  }
}

</script>

<style lang="scss" scoped>
button {
  width: 100px;
  height: 200px;
}
div {
  text-align: left;
}
</style>