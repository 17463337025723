import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../components/Admin.vue'
import Signup from '../components/auth/signup.vue'
import Signin from '../components/auth/signin.vue'
import Verify from '../components/Verify.vue'
import store from '../store'
import Successful from '../views/Successful.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/cancel',
    name: 'Cancel',
    component: () => import(/* webpackChunkName: "cancel" */ '../views/Cancel.vue')
  },
  {
    path: '/successful',
    name: 'Successful',
    component: Successful,
    beforeEnter: (to, from, next) => {
      console.log("ROUTER|index.js|succesful")
      next()
    }
  },
  {
    path: '/verify/:id',
    name: 'verify',
    component: Verify
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      //let adminUserId = 'UM2xXWDHbyUDf5dezf66AJ0wm9d2'
      if (store.state.userId == process.env.VUE_APP_ADMIN_USER_ID){ 
        //no userId at reload must reach component before userId exist
        //this happens before tryAutoLogin in app.js
        //i.e. you must come from a page to admin not directly from outside
        console.log("router|has userId")
        console.log("userId = ",store.state.userId)
        next()
      }else{
        console.log("router|not admin")
        next('/signin')
      }
    }
  },
  {
    path: '/:newQuote',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log(from)
      if (localStorage.getItem('token')){
        if (to.query.quote){
          console.log("router|localStorage>yes|query>yes|q.quote=",to.query.quote)
          next({name: 'Home', params: {newQuote: to.query.quote}})
        }else{
          next()
        }
      }else{
        let quoteId = null
        console.log(to.query.quote)
        if (to.query.quote){
          quoteId = to.query.quote
          next('/verify/:id' + quoteId)
        }else{
          next('/about')
        }
        
      }
    }
  },
  {
    path: '/quote/:id',
    component: Home
  }
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
