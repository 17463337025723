import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    customers: [],
    idToken: null,
    userId: null,
    quote: null
  },
  getters: {
    customers: state => {
      return state.customers;
    },
    quote: state => {
      return state.quote;
    },
    userId: state => {
      return state.userId;
    },
    token: state => {
      return state.idToken
    }

  },
  mutations: {
    addCustomer: (state,payload) => {
      console.log("mutations|=>record",payload)
      state.customers.push(payload) 
    },
    authUser (state, userData) {
      console.log("store|mutations|authUser()")
      //console.log("userData.token = ", userData.token)
      //console.log("userData.userId = ", userData.userId)
      state.idToken = userData.token 
      state.userId =  userData.userId
    },
    addQuote (state, quote)  {
      console.log("store|index.js|mutations|addQuote()")
      state.quote = quote
    }
  },
  actions: {
    tryAutoLogin ({commit}) {
      console.log("tryAutoLogin-1")
      const token = localStorage.getItem('token')
      //if no token return
      if (!token){
        return
      }
      const expirationDate = localStorage.getItem('expiresIn')
      const now = new Date()
      //if expired just return
      if (now >= expirationDate){
        return
      }
      console.log("tryAutoLogin|valid token")
      //has token and still valid
      const userId = localStorage.getItem('userId')
      commit('authUser',{
        token: token,
        userId: userId
      })
    },
    //this allows indirect call of mutations
    //especially for any async i.e. possibly delayed backend call etc..
    //.eg.get backend token then set it in state
    addCustomer: ({commit},payload) => {
      commit('addCustomer',payload)
    },
    signup: ({commit}, authData) => {
      //TODO: case when cookie removed but firebase has email auth
      //i.e. signup when previously signup with same email
      console.log("signup|email",authData.email)
      console.log("signup|password", authData.password)
    
      axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + process.env.VUE_APP_API_KEY,
      {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
      })
      .then(res => {
        console.log("store|signup().then")
        console.log("store|signup()|axios.post res = ",res)
        //commit to store
        commit('authUser',{
          token: res.data.idToken,
          userId: res.data.localId
        })
        console.log("INSIDE SIGNUP SETTING TOKEN TO BROWSER")
        //also put firebase auth token on client's browser store
        localStorage.setItem('token',res.data.idToken)
        localStorage.setItem('userId',res.data.localId)
        //also put the expiry date for token
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
        localStorage.setItem('expiresIn',expirationDate)
        //commit('donothing')
      })
      .catch(error => {
        console.log("ERROR POST FAILED...store|107",error)
        return false
      })
      return true

    },
    signin: ({commit}, authData) => {
      console.log("API KEY",process.env.VUE_APP_API_KEY)
      //changed to admin user id?
      axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.VUE_APP_API_KEY,
      {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
      })
      .then(res => {
        console.log(res)
        commit('authUser',{
          token: res.data.idToken,
          userId: res.data.localId
        })
        localStorage.setItem('token',res.data.idToken)
        //also put the expiry date for token
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
        localStorage.setItem('expiresIn',expirationDate)
        console.log("router|signedin|userId = ", res.data.localId)
        if (res.data.localId == process.env.VUE_APP_ADMIN_USER_ID){
          console.log("store|localId is admins")
          router.push({name: 'Admin'})
        }else{
          console.log("store|localId NOT admins ", res.data.localId)
          console.log("admins userid  = ", process.env.VUE_APP_ADMIN_USER_ID)
          router.push('/')
        }
        
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
  modules: {
  }
})
