<template>
 <div>
      <table>
      <tr>
       
        <td>
        
    
          <ul v-if="deliveryAddressInfo.customer">
            <li>
              <addressshow title="" :addressDetails="deliveryAddressInfo.customer"></addressshow>
              <!-- {{ deliveryAddressInfo.customer }} -->
            </li>
            <li>

            </li>
            <li>

            </li>
          </ul>
          <ul v-else-if="deliveryAddressInfo.pickup.natureOfContact.includes('customer')">
            <li>
              <addressshow title="" :addressDetails="deliveryAddressInfo.pickup"></addressshow>
            
            </li>
          </ul>
          <ul v-else-if="deliveryAddressInfo.dropoff.natureOfContact.includes('customer')">
            <li>
              <addressshow title="" :addressDetails="deliveryAddressInfo.dropoff"></addressshow>
              <!-- {{ deliveryAddressInfo.dropoff }} -->
            </li>
          </ul>
          <ul v-else>
            <li>
              NO CUSTOMER INFORMATION ROUTE!
            </li>
          </ul>
            <!-- Price : {{ quote.price }} pounds.<br>
            Date :  {{  quote.date[0] }} <br> 
            Distance : {{ Math.floor(trip_data.route_dist) }} miles.<br>
            Pickup area : {{ trip_data.pickup.substring(0,4) }}<br>
            Delivery area : {{ trip_data.dropoff.substring(0,4) }}<br> 
          -->
        </td>
       </tr>
       <tr> 
           <td>
          <button v-on:click="acceptCustomer()">Customer details correct</button>
        </td>
       </tr>
    </table>
 </div>
</template>

<script>
//todo import home from '../views/Home'
import Show from './AddressShow'
export default {
   props: {
     deliveryAddressInfo:{
       type: Object
     }
     
     },
     components: {
       addressshow: Show
     },
   data() {
    return {
    
    }
  },
  methods: {
     acceptCustomer() {
      this.$emit('clicked-customer');
    }
  }
}

</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
    width: 250px;
}
button {
  width: 200px;
  height: 40px;
}
</style>