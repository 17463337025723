<template>
  <div class="admin">
    admin    <button @click="getData">Get Customer's submitted data</button>    <button>todo</button>
    <hr>
   <div v-if="infoPresent">   
     {{ Object.keys(info).length }} record(s) from database present.<br>
     <hr>
     {{ getStore() }}
     <hr>
    
    <table>
         <tr>
              <ul>
              <li v-for="(each,index) in info" :key="each.id">
                  <td>
                      
                        <button :style=buttonStyle[index] @click="store(index)">Store</button>
                       <button :style=buttonStyle[index] @click="toggleActive(index)">
                           {{ index + 1}} |
                           {{ each.record.status }}|
                           {{ each.record.date }} |
                           {{ each.record.price }} |
                           {{ each.record.pickup.town }} >>
                           {{ each.record.dropoff.town}}
                        </button>
                <div :style="divStyle[index]">{{status[index]}}</div>
                <record v-if="active[index]" 
                :record="each.record" 
                :db_key="each.key"
                :parentIndex=index
                @deleteRecord="deleteRecord"
                @updateRecord="updateRecord"
                ></record>
             </td>
                 </li>
                   </ul> 
             </tr>
        </table> 
       
  </div>
  
  
  </div>
</template>
<script>
//import axios from 'axios'
import Vue from 'vue'
import firebase from 'firebase'
import Record from './Record'
import {eventBus} from '../main'
export default {
    created(){

    },
    data() {
        return {
            info: [],
            infoPresent: false,
            active: [],
            divStyle: [],
            status: [],
            buttonStyle: []
        }
    },
    components:{
        record: Record
    },
    computed:{
       reactiveDivStyle () {
           return this.divStyle;
       }
    },
    methods: {
        getStore(){
            let list = this.$store.getters.customers
            let keyList = [] 
            list.forEach(record => {
                keyList.push(record.key)
            })
            console.log("keyList =>",keyList)
            return keyList
        },
        store(index){
            //store info record with index as customer in vuex store
            console.log("admin|store()")
            console.log(this.info[index])
            let record = this.info[index]
            //console.log("record =>", record)
            //commit a mutation, dispatch an action[for async tasks]
            this.$store.commit('addCustomer',record)
            //OR//this.$store.dispatch('addCustomer',{record})
            console.log(this.$store.state.customers)

        },
        setStatus(state,index){
            this.status[index] = state
        },
        colorDivStyle(color,parentIndex){
            if (color == "red"){
                this.divStyle[parentIndex] = {color: "red"}
            }else if (color == "green"){
                this.divStyle[parentIndex] = {color: "green"}
            }else {
                //this.divStyle[parentIndex] = {color: "white"}
                //below because arrays are not reactive
                Vue.set(this.divStyle,parentIndex,{color: "white"})
            }
           
        },
        updateRecord(db_key,key,sub_key,value,parentIndex){
            console.log("admin|updateRecord|")
            console.log(db_key,key,sub_key, value)
            //get db ref
            let dataRef = firebase.database().ref("data/" + db_key)
            
            try {
                //update item with value
                dataRef.child(key).update({[sub_key]:value})
                this.getData()
                //visual feedback
                this.setStatus('updated',parentIndex)
                this.colorDivStyle("green",parentIndex)
                //tel eventbus of update
                eventBus.$emit('update',key,sub_key)
                setTimeout(() => { this.colorDivStyle("white",parentIndex)},4000)
            } catch (error) {
                console.log(error)
            }
            //console.log("divStyle", this.divStyle)
        },
        deleteRecord(db_key){
            console.log("admin|deleteRecord|db_key")
            console.log(db_key)
            //delete the record
            //get reference to data
            let dataRef = firebase.database().ref("data/" + db_key)
            //delet using reference
            dataRef.remove()
            //call to get updated data
            this.getData()
        },
         isActive(n){
            return this.active[n]
        },
        toggleActive(n){
            //console.log(n)
            if (this.active[n]){
                Vue.set(this.active,n,!this.active[n])
            } else {
                Vue.set(this.active,n,!this.active[n])
            }
            //console.log(this.active)
        },
        getData(){
            //use firebase to get data
            var fbauth = firebase.auth()
            console.log(fbauth)

            //var admin = require("firebase-admin")
            var serviceAccount = require("../serviceAccountKey.json")
            console.log(serviceAccount)
            firebase.database().ref('data').once('value')
            .then((data) => {
                
                const info = []
                const obj = data.val()
                //console.log(Object.keys(data.val())[0])
                 //console.log(Object.keys(obj))
                //console.log(data)
                for (let key in obj) {
                    var db_key = key
                    info.push({
                    record: obj[key],
                    key: db_key
                    //key: Object.keys(obj)[0]
                    //id: key,
                    //pickup: obj[key].pickup,
                    //dropoff: obj[key].dropoff,
                    //customer: obj[key].customer,
                    //price: obj[key].price,
                    //date: obj[key].date
                    })
                }
                //console.log(info)
                this.info = info
                this.infoPresent = true
                //set color style for top level record buttons based upon records status
                //fat arrow function so access to outer this works
                this.info.forEach((each,index) => {
                    //console.log(this,each.record.status,index)
                    let status = each.record.status
                    if (status == 'clicked-to-book'){
                        this.buttonStyle[index] = {color: 'orange'}
                    }else if (status == 'accept-delivery'){
                        this.buttonStyle[index] = {color: 'black'}
                    } else if (status == 'accept-customer'){
                        this.buttonStyle[index] = {color: 'red'}
                    } else if (status == 'check-payment'){
                         this.buttonStyle[index] = {color: 'green'}
                    }else{
                         this.buttonStyle[index] = {color: 'red'}
                    }

                })
            })
            .catch((error) => {
                console.log(error)
            })
        }//get data
    }//methods
}

</script>



<style lang="scss" scoped>
p {
  padding: 0px;
  text-align: left;
  text-indent: 100px;
  
}

</style>