<template>
<div>
    <h3>Sign up</h3>
    <form @submit.prevent="submit()">
        <label for="">email</label>
        <input type="email" v-model="email"><br>
        <label for="">password</label>
        <input type="password" v-model="password"><br>
        <button type="submit">submit</button>
    </form>
</div>    
</template>
<script>
//import axios from 'axios'
export default {
    data() {
        return {
            email: 'enter your email',
            password: '',
            disabled: ''
        }
    },
    methods: {
        submit(){
            const data = {
                email: this.email,
                password: this.password
            }
            //console.log("signup|onSubmit()")
            //console.log("email ->",this.email)
            //console.log("password ->", this.password)
            //https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]
            //
            this.$store.dispatch('signup',{email: data.email, password: data.password})
           /* axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBjhWRwSpwQqfKQD547fhcpElcr0hLOj6o',
            {
                email: data.email,
                password: data.password,
                returnSecureToken: true
            })
            .then(res => console.log(res))*/
        }  
    }
   
}
</script>
