<template>
<div v-if="admin">
    <nav>
    <router-link  to="/admin">admin</router-link> 
     |<router-link  to="/home">home</router-link>  
    </nav>
</div>
</template>
<script>
import store from '../../store'

export default {
    created() {
        this.isAdmin()
    },
    mounted(){
        this.isAdmin()
    },
    data() {
        return {
            admin: false
        }
    },
    methods: {
     isAdmin(){
         //if user admin set admin to true
         console.log("navigation|isAdmin()")
         console.log(store.getters.userId)
         //TODO: make logic for auth token admin
         console.log("navigation|cookie check")
         //console.log("token =",localStorage.getItem("token"))
         console.log("nav|userId = ",store.state.userId)
         let auid = 'UM2xXWDHbyUDf5dezf66AJ0wm9d2'
         let caseAdmin = (auid == store.state.userId)
         if (caseAdmin){
             console.log("nav| ADMIN MATCH")
             console.log(store.getters.userId)
         }else{
             console.log("nav|NOT ADMIN userId")
             console.log("userId = ", store.getters.userId)
         }
         
         if (caseAdmin){
             console.log("nav|is admin")
             this.admin = true
             }else{
                 console.log("nav|not admin")
                 this.admin = false
             }
          
     }   
    }
}
</script>
<style lang="scss">
    

</style>